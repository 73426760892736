import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "src/react/layout/container";
import logo from "src/assets/images/logos/cornerstone-logo-nav.svg";
import NavBar from "./navbar";
import santaHat from "src/assets/images/logos/santa-hat.png";

function Header({ hideNav }) {
  return (
    <Wrapper>
      <LogoContainer>
        <LogoLink data-selector="LogoLink" to="/">
          <Logo src={logo} alt="Cornerstone Logo" width={300} height={42} />
        </LogoLink>
      </LogoContainer>
      <NavBar hideNav={hideNav} />
    </Wrapper>
  );
}

export default Header;

const breakPoint = "@media (min-width: 600px)";

const Wrapper = styled.header`
  padding-top: 4px;

  ${breakPoint} {
    padding-top: 10px;
  }
`;

const Logo = styled.img`
  display: block;
  width: 170px;
  margin-bottom: -10px;

  ${breakPoint} {
    width: 240px;
    margin-bottom: -5px;
  }
`;

const LogoContainer = styled(Container)`
  display: flex;
`;

const LogoLink = styled(Link)`
  display: inline-block;
  vertical-align: bottom;
  margin-left: -2px;
  position: relative;

  :before {
    content: "";
    position: absolute;
    z-index: 1;
    top: -7px;
    left: -11px;
    width: 34px;
    height: 34px;
    background-image: url("${santaHat}");
    background-position: left;
    background-size: cover;

    ${breakPoint} {
      top: -15px;
      left: -15px;
      width: 47px;
      height: 47px;
    }
  }
`;